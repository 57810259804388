.mat-mdc-text-field-wrapper.mdc-text-field {
  background-color: theme("colors.white");
  border: 1px solid theme("colors.cancel");
  border-radius: 10px;
}

.mat-mdc-text-field-wrapper:hover {
  border-color: gray;
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--disabled {
  background-color: theme("colors.cancel");
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--disabled:hover {
  border: 1px solid theme("colors.cancel");
}

.mat-mdc-form-field-focus-overlay {
  background-color: theme("colors.white");
}

.mat-form-field-invalid .mat-mdc-text-field-wrapper {
  border: 1px solid theme("colors.alert");
}

.custom-form .mat-mdc-text-field-wrapper {
  margin-top: 20px;
  overflow: unset;
}

.custom-form .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.custom-form .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix .mdc-floating-label {
  position: absolute;
  top: 0;
  left: -16px;
  color: theme("colors.black");
}

.mat-form-field-invalid .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix .mdc-floating-label {
  color: theme("colors.alert");
}

.custom-form .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: unset;
}

.no-subscript .mat-mdc-form-field-subscript-wrapper {
  height: 0 !important;
}

.clear-input .mat-mdc-text-field-wrapper.mdc-text-field {
  background-color: unset;
  border: none !important;
  cursor: default;
}

.clear-input .mat-mdc-text-field-wrapper.mdc-text-field input {
  color: rgba(0, 0, 0, 0.87);
}

mat-label.star-inserted::after {
  content: " *";
}
