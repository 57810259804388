.mat-mdc-table .mat-mdc-header-cell {
  color: theme("colors.table-header-text") !important;
  background-color: theme("colors.table-header") !important;
  border-right: 1px solid theme("colors.secondary");
  font-weight: bold !important;
}
.mat-mdc-table .mat-mdc-form-field-subscript-wrapper {
  height: 0 !important;
}

.mat-mdc-table .mat-mdc-text-field-wrapper .mdc-text-field__input,
.mat-mdc-table .mat-mdc-text-field-wrapper .mat-mdc-select ,
.mat-mdc-table .mat-mdc-text-field-wrapper .mat-mdc-select-trigger {
  height: 100% !important;
}

.mat-mdc-table .mat-mdc-text-field-wrapper .mdc-text-field__input {
  height: 100% !important;
}

.mat-mdc-table .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea),
.mat-mdc-table .mat-mdc-form-field-infix {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 1rem;
  height: 2rem;
  max-height: 2rem;
}

.mat-mdc-no-data-row,
.mdc-data-table__cell {
  background-color: theme("colors.table-cell") !important;
}

.mat-mdc-no-data-row {
  text-align: center;
  height: 50px;
}

.mat-mdc-paginator {
  background: transparent;
  margin-top: 10px;
}
