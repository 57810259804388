@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("~@angular/material/prebuilt-themes/indigo-pink.css");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mdc-line-ripple {
  display: none !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.active-status {
  display: inline-block;
  border-radius: 3px;
  background: #cfe7cf;
  color: #48a048;
  font-weight: bold;
  padding: 0 5px;
}

.active-status::after {
  content: "ACTIVE";
}

.active-status.disable {
  background: #e7cfd5;
  color: #a0485a;
}

.active-status.disable::after {
  content: "DISABLE";
}

.is-cancel {
  display: inline-block;
  border-radius: 3px;
  background: #cfe7cf;
  color: #48a048;
  font-weight: bold;
  padding: 0 5px;
}

.is-cancel::after {
  content: "Is Cancel";
}

.is-cancel.disable {
  background: #e7cfd5;
  color: #a0485a;
}

.is-cancel.disable::after {
  text-decoration: line-through;
}
