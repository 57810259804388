.text-btn {
  display: flex;
  width: fit-content;
  color: theme("colors.primary");
  box-sizing: border-box;

  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  margin: 10px;
}
.text-btn * {
  margin-right: 5px;
  font-size: 15px;
}

.text-btn:hover {
  color: theme("colors.primary");
  border-color: theme("colors.primary");
}

.mat-mdc-button.confirm-btn {
  background-color: theme("colors.primary");
}

.mat-mdc-button.alert-btn {
  background-color: theme("colors.alert");
}

.mat-mdc-button.alert-btn *,
.mat-mdc-button.confirm-btn * {
  color: theme("colors.white");
}

.mat-mdc-button.cancel-btn {
  background-color: theme("colors.cancel");
}

.mat-mdc-button.outline-btn {
  border: 1px solid theme("colors.primary");
}

.mat-mdc-button.outline-btn * {
  color: theme("colors.primary");
}
